<template>
  <div class="step-card">
    <div class="step-card-icon">0{{ step.id }}</div>
    <div class="step-card-title">{{ step.title }}</div>
    <div class="step-card-content" v-if="full" v-html="step.description" />
    <div class="step-card-content" v-else v-html="step.excerpt" />
  </div>
</template>
<script>
export default {
  name: "StepCard",
  props: {
    step: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.step-card {
  position: relative;
  display: block;
  padding: $spacer;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  text-align: center;
  height: 100%;

  &-icon {
    position: relative;
    display: inline-block;
    margin: -1 * $spacer 0 0 0;
    width: $spacer * 3.5;
    height: $spacer * 3.5;
    line-height: $spacer * 3.5;
    transform: translateY(-1 * $spacer);

    border-radius: 100%;
    background-color: $primary;

    @include set-font-style($font-size-level-5, $font-weight-bold, $white);
  }

  &-title {
    position: relative;
    display: block;
    margin: 0 0 $spacer 0;

    @include set-font-style($font-size-level-5, $font-weight-bold, $gray-900, uppercase);
  }

  &-content {
    position: relative;
    display: block;

    @include set-font-style($font-size-level-4, $font-weight-light);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
    }
  }
}
</style>
