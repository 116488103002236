<template>
  <PrimaryMenu />

  <Header title="Hogyan zajlik az elektromos kisautó kölcsönzés?" />

  <SectionBlock>
    <SectionTitle title="Hogyan béreljek Kicsikocsit?" />
    <SectionContent>
      <BRow gutter-y="8">
        <BCol sm="12" v-for="step in howToRentSteps" :key="step.id">
          <StepCard :step="step" full />
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import StepCard from "@/components/units/cards/StepCard";

export default {
  name: "HowToRent",
  title: "Hogyan zajlik az elektromos kisautó kölcsönzés?",
  components: {
    StepCard,
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionTitle,
    SectionContent,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
